// TODO: I'm using Object.prototype.whatever all through here, but that's not the best way of doing this (since the properties show up when doing `in` enumeration (which you shouldn't be doing in ES6, we have Object.keys())). I should figure out the right way of doing this.

Array.prototype.first = function () {
    return this[0];
};
// TODO: implement Array methods on iterators. Iterator prototypes are hidden but can be accessed with Object.getPrototypeOf

Array.prototype.any = Array.prototype.some;

// If Array.filter() is called with no args, it should filter for truthiness
Array.prototype.filter = (function () {
    const oldFilter = Array.prototype.filter;

    return function filter (f) {
        return oldFilter.call(this, f ? f : (v) => !!v);
    };
})();

// Doesn't ignore prepositions :(
String.prototype.toTitleCase = function () {
    return this
        .split(" ")
        .map(word => word.toLowerCase())
        .map((word) => word[0].toUpperCase() + word.slice(1))
        .join(" ");
};

// JS implimentation of Django's toSlug
// > Converts to ASCII. Converts spaces to hyphens. Removes characters that aren’t alphanumerics, underscores, or hyphens. Converts to lowercase. Also strips leading and trailing whitespace.
String.prototype.toSlug = function () {
    return this.trim().replace(/\s/g, "-").replace(/[^\w-]/g, "").toLowerCase();
};

Object.prototype.toJSONString = function () {
    return JSON.stringify(this);
};

// Reduces an array of objects into a single object
// This could definitely have a better name
Array.prototype.reduceObjects = function () {
    this.reduce((acc, val) => ({ ...acc, ...val}), {});
};

// [a, b, c].getEach("foo")
// is like Ruby's [a, b, c].map(&:foo)
// Could also do this more directly in line ruby, Array.prototype.map("foo".getter())
Array.prototype.getEach = function (key) {
    return this.map(val => val[key]);
}

Object.merge = function (...objects)  {
    return Object.assign({}, ...objects);
}

Object.prototype.entries = function () {
    return Object.entries(this);
};

Object.prototype.toArray = function () {
    return Array.from(this);
}

// a.bind("val", 5).bind("val", 6)({ val: 7 }), what is "val"? I almost want to say 6 lol, but no it's got to be 7
Function.prototype.bindNamedArgument = function (name, value) {
    return function ({ ...args }) {
        this({ name: value, ...args });
    }
};

Function.prototype.bindNamedArguments = function ({ ...argsToBind }) {
    return function ({ ...args }) {
        this({ ...argsToBind, ...args });
    }
};

// If fetch is called without a .then, then console.log the result
window.fetch = (function () {
    const oldFetch = fetch;
    return function (...args) {
        let hasThen = false;
        const p = oldFetch(...args).then(function (res) {
            if (!hasThen) {
                console.log(res);
            }
            return res;
        });
        p.then = function (...args) {
            hasThen = true;
            return Promise.prototype.then.apply(p, args);
        };
        return p;
    };
})();
